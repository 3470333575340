import type { RMDXOpts as Opts, RDMDProps as Props, RMDXProps } from '@readme/iso';
import type { RunOpts } from '@readme/mdx/dist/lib/run';
import type { MDXModule } from 'mdx/types';

import * as rmdx from '@readme/mdx';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import rdmdComponentOverrides from '@core/utils/rdmdComponentOverrides';

import './style.scss';

type RunResults = Awaited<ReturnType<typeof rmdx.run>>;

const { TailwindStyle } = rmdx.Components;

const overrides = Object.entries(rdmdComponentOverrides).reduce((acc, [key, value]) => {
  acc[key] = { default: value };
  return acc;
}, {});

export async function exec(body: string, opts: Opts, cb: (module: RunResults) => void) {
  const customBlocksByExport = { ...opts.components };
  const customBlocks = {};

  const promises = Object.entries(opts.components || {}).map(async ([tag, source]): Promise<[string, MDXModule]> => {
    const code = await rmdx.compile(source, { ...opts, components: {} });
    const mod = await rmdx.run(code, { ...opts, components: {} } as RunOpts);
    Object.keys(mod).forEach(subTag => {
      if (['toc', 'Toc', 'default', 'stylesheet'].includes(subTag)) return;

      customBlocksByExport[subTag] = source;
    });

    return [tag, mod];
  });

  (await Promise.all(promises)).forEach(([tag, node]) => {
    customBlocks[tag] = node;
  });

  const components = { ...overrides, ...customBlocks };
  const vfile = await rmdx.compile(body, { ...opts, components: customBlocksByExport, useTailwind: true });
  const module = await rmdx.run(vfile, { ...opts, components } as RunOpts);

  cb(module);

  return module;
}

function useRMDX(body: string, _, optsParam: Opts) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const opts = useMemo(() => optsParam, [JSON.stringify(optsParam)]);
  const [Content, setContent] = useState<RunResults>();
  const isMounted = useRef<boolean>(true);
  const renderCount = useRef<number>(0);
  const [hasError, setError] = useState<string | null>(null);

  useEffect(() => {
    isMounted.current = true;
    renderCount.current += 1;
    const id = renderCount.current;

    const run = async () => {
      try {
        await exec(body, opts, content => {
          if (!isMounted.current) return;

          // Only set content if the execution is for the latest render to avoid
          // re-renders from stale executions.
          if (id !== renderCount.current) return;

          setContent(() => content);
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setError(e.stack);
      }
    };

    run();

    return () => {
      isMounted.current = false;
    };
  }, [body, opts]);

  if (hasError) {
    throw new Error(hasError);
  }

  return Content;
}

const Dehydrated = ({ string = '', Tag = 'div', ...rest }: { Tag?: RMDXProps['Tag']; string?: string }) => (
  <Tag {...rest} dangerouslySetInnerHTML={{ __html: string }} />
);

export const TOC = ({ body, children, dehydrated, opts = {} }: Props) => {
  const Content = useRMDX((body || children) as string, true, opts);

  return Content ? <Content.Toc /> : <Dehydrated string={dehydrated} />;
};

const RMDX = ({
  body,
  children = body,
  className,
  dehydrated,
  css,
  excerpt,
  opts = {},
  skipBaseClassName = false,
  Tag = 'div',
  darkModeDataAttribute = undefined,
  ...rest
}: RMDXProps) => {
  const doc: string = excerpt
    ? rmdx.mdx({ type: 'root', children: [rmdx.mdast(children || '').children[0]] })
    : (children as string);
  const Content = useRMDX(doc, false, opts);
  const classes = [
    skipBaseClassName !== true && 'markdown-body',
    className || '',
    opts.useTailwindRoot && 'readme-tailwind',
  ];
  const props = { ...rest, className: `rm-Markdown ${classes.filter(c => c).join(' ')}`, 'data-testid': 'RDMD' };
  const [useAppDataAttribute, setUseAppDataAttribute] = useState(!darkModeDataAttribute);

  useEffect(() => {
    if (darkModeDataAttribute) return;

    const appEl = document.querySelector('[data-color-mode]');
    setUseAppDataAttribute(['light', 'dark'].includes(appEl?.getAttribute('data-color-mode') || ''));

    /*
     * @note: This is to support dark mode in tailwind styles. In the preview
     * panel, we want to be able to set it to based on a toggle, independent of
     * the rest of the pages scheming. So we can simple use the data attribute
     * selector.
     *
     * I'm maybe not savvy enough to figure out how to combine that with a
     * media query for tailwind, so we check if the main app is using system
     * settings (when `data-color-mode` === `null` or `'system'`). Or if it's
     * been set, we switch to using the data attribute.
     */
    const observer = new MutationObserver((records: MutationRecord[]) => {
      records.forEach(record => {
        if (!(record.target instanceof HTMLElement)) return;

        const { dataset } = record.target;
        setUseAppDataAttribute(['dark', 'light'].includes(dataset.colorMode || ''));
      });
    });

    observer.observe(document.documentElement, {
      attributes: true,
      attributeFilter: ['data-color-mode'],
    });

    // eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  }, [darkModeDataAttribute]);

  if (!Content) {
    return <Dehydrated {...props} string={dehydrated} Tag={Tag} />;
  }

  return (
    <Tag {...props}>
      {/* @todo: figure out how to clean this up */}
      {!!css && <style>{css}</style>}
      <TailwindStyle darkModeDataAttribute={useAppDataAttribute ? 'data-color-mode' : darkModeDataAttribute}>
        <Content.default />
      </TailwindStyle>
    </Tag>
  );
};

export default RMDX;
